import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Field, Form } from "@progress/kendo-react-form";
import {
  Loader,
  ErrorFallback,
  buildNotification,
  GenerateNotification,
  TextAreaInput,
  SliderCard,
} from "smart-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import TestCaseLookupSlider from "../../TestCases/Components/Lookup/TestCaseLookupSlider";
import DataFieldContentSlider from "../../Core/Components/DataFieldContentSlider/DataFieldContentSlider";
import {
  LookUp_Mode,
  Lookup_Selection_Mode,
  CONTAINS_OP,
} from "../../../constants/applicationConstants";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../constants/eventDataTypes";
import { useAuth } from "../../Core/Context/AuthContext";
import DropdownList from "../../Core/Components/DropdownList/DropdownList";
import { listTestCase } from "../../TestCases/Services/TestCaseService";
import ValidatePermissions from "../../../Utils/Auth/ValidatePermissions";
import {
  listRunSetDetail,
  createRunSetDetail,
} from "../Services/RunSetDetailService";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
import {
  CREATE_MESSAGE,
  ERROR_MESSAGE,
} from "../../../constants/notificationMessages";
import "./RunSetDetail.scss";
/**
 * Run Set Detail main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Run Set Detail component.
 */
const RunSetDetail = ({ runSet }) => {
  const { getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  const { accessToken } = getTokensFromStorage();
  const [testCaseSlideShow, setTestCaseSlideShow] = React.useState(false);
  const [selectedTCLookupItems, setSelectedTCLookupItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [testCaseData, setTestCaseData] = React.useState([]);
  const [currentTestCase, setCurrentTestCase] = React.useState();
  const [runSetDetail, setRunSetDetail] = React.useState([]);
  const [disableBtn, setDisableBtn] = React.useState(true);
  const [showDFModal, setShowDFModal] = React.useState(false);
  const [currentItemTestcase, setCurrentItemTestcase] = React.useState("");
  const [editorField, setEditorField] = React.useState({
    name: "",
    value: "",
    label: "",
    title: "",
  });
  /**
   * Used to show/hide test case lookup slide
   */
  const handleTestCaseSlide = () => {
    if (testCaseSlideShow) {
      setTestCaseSlideShow(false);
    } else {
      setTestCaseSlideShow(true);
    }
  };

  /**
   * handle dropdown Change
   * @e object
   */
  const handleDropdownChange = (e) => {
    setCurrentTestCase(e.value);
  };

  /**
   * handle dropdown Change
   * @e object
   */
  const addTestCaseItem = () => {
    if (currentTestCase) {
      // Finding the largest uc_ossi_run_seq value
      let largestRunSeq = runSetDetail.reduce(
        (max, obj) => Math.max(max, obj.uc_ossi_run_seq),
        0
      );
      const exists = runSetDetail.find(
        (item) =>
          item.uc_ossi_app_test_id === currentTestCase.uc_ossi_app_test_id &&
          item.uc_ossi_test_case_id === currentTestCase.uc_ossi_test_case_id
      );
      if (!exists && ValidatePermissions(currentTestCase)) {
        setRunSetDetail((prevTestData) => [
          ...prevTestData,
          {
            uc_ossi_run_seq: ++largestRunSeq,
            uc_ossi_run_ord: "",
            uc_ossi_app_test_id: currentTestCase.uc_ossi_app_test_id, // Example test ID
            uc_ossi_test_case_id: currentTestCase.uc_ossi_test_case_id, // Example test case ID
            uc_ossi_disable_flg: false,
            uc_ossi_user_cnt: "",
            uc_ossi_step_run_till_expr: "",
            uc_ossi_sleep_sec: "",
            uc_ossi_step_run_id: "",
          },
        ]);
        setCurrentTestCase("");
        setDisableBtn(false);
      } else {
        GenerateNotification(
          buildNotification({
            title: "Invalid Test Case!",
            description: "An error occurred while selecting the test case",
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
        return;
      }
    }
  };

  React.useEffect(() => {
    getRunSetDetail();
    (async () => {
      const tests = await fetchTestCases("");
      setTestCaseData(tests);
    })();
  }, []);

  /**
   * fetch Tests
   * @filter {Array}
   */
  const fetchTestCases = async (filter) => {
    const { accessToken } = getTokensFromStorage();
    setLoading(true);
    const response = await listTestCase({
      accessToken: accessToken,
      limit: "50",
      offset: 1,
      filter: filter ? filter : [],
      moduleName: "TestCase",
      isServiceWorker: false,
    });
    setLoading(false);
    return response.tests;
  };

  /**
   * filter Change
   * @event object
   */
  const filterChange = async (event) => {
    let filter = [
      {
        ColumnName: "TestCaseId",
        Op: CONTAINS_OP,
        ColumnValue: event.filter.value,
      },
    ];
    const tests = await fetchTestCases(filter);
    setTestCaseData(tests);
  };

  /**
   * get Run Set Detail
   */
  const getRunSetDetail = async () => {
    setLoading(true);
    const response = await listRunSetDetail({
      accessToken: accessToken,
      limit: "50",
      offset: 1,
      filter: [
        {
          ColumnName: "uc_ossi_run_id",
          Op: "eq",
          ColumnValue: runSet?.uc_ossi_run_id,
        },
      ],
      moduleName: "RunSetDetail",
      sort: ["uc_ossi_run_ord"],
      isServiceWorker: false,
      uc_ossi_run_id: runSet?.uc_ossi_run_id,
    });
    setRunSetDetail(response.runSetDetail);
    setLoading(false);
  };

  React.useEffect(() => {
    // Finding the largest uc_ossi_run_seq value
    let largestRunSeq = runSetDetail.reduce(
      (max, obj) => Math.max(max, obj.uc_ossi_run_seq),
      0
    );
    const updatedCheckedDataItems = selectedTCLookupItems
      .filter(
        (selectedItem) =>
          !runSetDetail.some(
            (dataItem) =>
              dataItem.uc_ossi_app_test_id ===
                selectedItem.uc_ossi_app_test_id &&
              dataItem.uc_ossi_test_case_id ===
                selectedItem.uc_ossi_test_case_id
          )
      )
      .map((item) => ({
        ...item,
        uc_ossi_run_seq: ++largestRunSeq,
        uc_ossi_run_ord: "",
        uc_ossi_disable_flg: false,
        uc_ossi_user_cnt: "",
        uc_ossi_step_run_till_expr: "",
        uc_ossi_sleep_sec: "",
        uc_ossi_step_run_id: "",
      }));
    if (updatedCheckedDataItems.length > 0) {
      setDisableBtn(false);
    }
    setRunSetDetail([...runSetDetail, ...updatedCheckedDataItems]);
  }, [selectedTCLookupItems]);

  /**
   * handle Test Case Drag Start
   * @param e, itemTestcase
   */
  const handleTestCaseDragStart = (e, itemTestcase) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(itemTestcase));
  };

  /**
   * handle Test Case Drag Drop
   * @param e, itemTestcase
   */
  const handleTestCaseDrop = (e, itemTestCase) => {
    e.preventDefault();
    const draggedItem = JSON.parse(e.dataTransfer.getData("text/plain"));
    const dropTargetItem = itemTestCase;

    // Find the index of the dragged item and the drop target item
    const draggedIndex = runSetDetail.findIndex(
      (item) =>
        item.uc_ossi_test_case_id === draggedItem.uc_ossi_test_case_id &&
        item.uc_ossi_app_test_id == draggedItem.uc_ossi_app_test_id
    );
    const dropTargetIndex = runSetDetail.findIndex(
      (item) =>
        item.uc_ossi_test_case_id === dropTargetItem.uc_ossi_test_case_id &&
        item.uc_ossi_app_test_id == dropTargetItem.uc_ossi_app_test_id
    );

    // If both items are found, swap their positions in the runSetDetail array
    if (draggedIndex !== -1 && dropTargetIndex !== -1) {
      const updatedTestData = [...runSetDetail];
      const [draggedItem] = updatedTestData.splice(draggedIndex, 1);
      updatedTestData.splice(dropTargetIndex, 0, draggedItem);
      setRunSetDetail(updatedTestData);
      setDisableBtn(false);
    }
  };

  /**
   * save Run Detail
   */
  const saveRunDetail = async () => {
    let order = 1;
    const requestData = runSetDetail?.map((testCase) => ({
      uc_ossi_run_seq: testCase?.uc_ossi_run_seq,
      uc_ossi_run_ord: order++,
      uc_ossi_app_test_id: testCase?.uc_ossi_app_test_id,
      uc_ossi_test_case_id: testCase?.uc_ossi_test_case_id,
      uc_env_id: "%",
      uc_ossi_disable_flg: testCase?.uc_ossi_disable_flg ? 1 : 0,
      uc_ossi_user_cnt: testCase?.uc_ossi_user_cnt,
      uc_ossi_step_run_till_expr: testCase?.uc_ossi_step_run_till_expr,
      uc_ossi_sleep_sec: testCase?.uc_ossi_sleep_sec,
      uc_ossi_step_run_id: testCase?.uc_ossi_step_run_id,
    }));
    const filteredData = requestData?.filter(
      (item) =>
        !item?.uc_ossi_test_case_id ||
        !item?.uc_ossi_app_test_id ||
        !item?.uc_ossi_run_ord
    );
    if (requestData.length != 0 && filteredData.length > 0) {
      GenerateNotification(
        buildNotification({
          title: "Invalid Run Set Details",
          description: ERROR_MESSAGE?.description,
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      return;
    }
    setLoading(true);
    let response = await createRunSetDetail({
      data: {
        tenant_id: runSet?.tenant_id,
        uc_ossi_run_id: runSet?.uc_ossi_run_id,
        usr_ossi_runset_dtl: requestData,
      },
      moduleName: "runset",
      isServiceWorker: false,
      uc_ossi_run_id: runSet?.uc_ossi_run_id,
    });
    try {
      if (response.IsSuccess) {
        GenerateNotification(
          buildNotification(CREATE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification(ERROR_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * handle Input Change
   * @param testId
   * @param testCaseId
   * @param e
   */
  const handleInputChange = (testId, testCaseId, e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    // Check if the name is uc_ossi_user_cnt and newValue exceeds 9 digits
    if (name === "uc_ossi_user_cnt" && newValue.length > 9) {
      return; // Exit the function without updating the state
    }
    setRunSetDetail((prevTestData) =>
      prevTestData.map((item) => {
        if (
          item.uc_ossi_app_test_id === testId &&
          item.uc_ossi_test_case_id === testCaseId
        ) {
          return {
            ...item,
            [name]: newValue,
          };
        }
        return item;
      })
    );
    if (name == "uc_ossi_step_run_till_expr") {
      setCurrentItemTestcase((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }

    setDisableBtn(false);
  };

  /**
   * delete Test Case
   * @param testId
   * @param testCaseId
   */
  const deleteTestCase = (testId, testCaseId) => {
    setRunSetDetail((prevTestData) =>
      prevTestData.filter(
        (item) =>
          item.uc_ossi_app_test_id !== testId ||
          item.uc_ossi_test_case_id !== testCaseId
      )
    );
    setDisableBtn(false);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-px-4">
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <div className="runset-detail">
            <div>
              <div className="k-py-2 field-wrapper add-test-case-row">
                <div className="k-align-items-center k-d-flex">
                  <span className="k-mr-2 test-case-dropdown-label">
                    Select Test Case:
                  </span>
                </div>
                <DropdownList
                  textField="TestCaseId"
                  label="Test"
                  dataItemKey="uc_ossi_test_case_id"
                  className="testcase-dropdown k-align-self-end"
                  tenantList={testCaseData}
                  value={currentTestCase}
                  defaultItem={{
                    uc_ossi_test_case_id: "Select Test Case...",
                    disabled: true,
                  }}
                  skipDisabledItems={false}
                  handleChange={handleDropdownChange}
                  onFilterChange={filterChange}
                />
                <div className="k-d-flex">
                  <Button
                    themeColor={"primary"}
                    className="k-mx-1"
                    icon="search"
                    onClick={() => setTestCaseSlideShow(true)}
                  ></Button>
                  <Button
                    themeColor={"primary"}
                    className="k-mx-1"
                    onClick={() => addTestCaseItem()}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="table-container">
                <table className="run-set-detail-table">
                  <tr>
                    <th>Test </th>
                    <th>Test Case</th>
                    <th>No Of Concurrent User</th>
                    <th>Run While Condition Is True</th>
                    <th>Disable</th>
                    <th>Action</th>
                  </tr>
                  <Form
                    className="add-arg-test-form"
                    render={(formRenderProps) => (
                      <>
                        {runSetDetail?.map((itemTestcase) => (
                          <tr
                            className=""
                            draggable // Add draggable attribute
                            onDragStart={(e) =>
                              handleTestCaseDragStart(e, itemTestcase)
                            }
                            onDragOver={(e) => e.preventDefault()} // Allow drop
                            onDrop={(e) => handleTestCaseDrop(e, itemTestcase)}
                          >
                            <td className="test-id-cell">
                              <span>{itemTestcase.uc_ossi_app_test_id}</span>
                            </td>
                            <td className="test-id-cell">
                              <span>{itemTestcase.uc_ossi_test_case_id}</span>
                            </td>
                            <td className="input-cell">
                              <TextBox
                                id={"uc_ossi_user_cnt"}
                                name={"uc_ossi_user_cnt"}
                                value={itemTestcase.uc_ossi_user_cnt}
                                type="number"
                                onChange={(e) =>
                                  handleInputChange(
                                    itemTestcase.uc_ossi_app_test_id,
                                    itemTestcase.uc_ossi_test_case_id,
                                    e
                                  )
                                }
                              />
                            </td>
                            <td className="textarea-cell">
                              <div className="field-wrapper data-field-wrapper k-pos-relative">
                                <Field
                                  key={"uc_ossi_step_run_till_expr"}
                                  id={"uc_ossi_step_run_till_expr"}
                                  name={"uc_ossi_step_run_till_expr"}
                                  data={itemTestcase.uc_ossi_step_run_till_expr}
                                  onChange={(e) =>
                                    handleInputChange(
                                      itemTestcase.uc_ossi_app_test_id,
                                      itemTestcase.uc_ossi_test_case_id,
                                      e
                                    )
                                  }
                                  type={"text"}
                                  component={TextAreaInput}
                                  row={1}
                                  optional={false}
                                />
                                <span
                                  onClick={() => {
                                    setEditorField({
                                      name: "uc_ossi_step_run_till_expr",
                                      value: "",
                                      title: "",
                                    });
                                    setCurrentItemTestcase(itemTestcase);
                                    setShowDFModal(true);
                                  }}
                                  className="open-editor-icon k-pos-absolute"
                                >
                                  <FontAwesomeIcon
                                    className="icon-full-screen k-mr-1"
                                    icon={faExpand}
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <Switch
                                size={"large"}
                                label={"Disabled"}
                                onLabel={"Yes"}
                                offLabel={"No"}
                                id={"uc_ossi_disable_flg"}
                                name={"uc_ossi_disable_flg"}
                                checked={itemTestcase.uc_ossi_disable_flg}
                                onChange={(e) =>
                                  handleInputChange(
                                    itemTestcase.uc_ossi_app_test_id,
                                    itemTestcase.uc_ossi_test_case_id,
                                    e
                                  )
                                }
                              />
                            </td>
                            <td>
                              <span
                                className="k-icon k-font-icon k-i-trash trash-icon"
                                onClick={() =>
                                  deleteTestCase(
                                    itemTestcase.uc_ossi_app_test_id,
                                    itemTestcase.uc_ossi_test_case_id
                                  )
                                }
                              ></span>
                            </td>
                          </tr>
                        ))}
                        {runSetDetail.length == 0 && (
                          <tr>
                            <td colSpan={7} className="no-data-found">
                              No Run Set Details
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  />
                </table>
              </div>

              <div className="k-text-right k-pb-20">
                <Button
                  themeColor={"primary"}
                  className="k-my-1"
                  onClick={saveRunDetail}
                  disabled={disableBtn}
                >
                  Save
                </Button>
              </div>
            </div>
            <TestCaseLookupSlider
              show={testCaseSlideShow}
              handleSlide={handleTestCaseSlide}
              setSelectedLookupItems={setSelectedTCLookupItems}
              selectedLookupItems={selectedTCLookupItems}
              lookupSelectionMode={Lookup_Selection_Mode.multiple}
              mode={LookUp_Mode.LookUp}
            />
          </div>
          <div className="data-field-section runset-slider">
            <DataFieldContentSlider show={showDFModal}>
              <SliderCard
                handleSlide={() => {
                  setShowDFModal(false);
                }}
                title={"Run While Condition is true"}
                className="data-field-slider"
              >
                <SliderCard.Body>
                  <Form
                    className=""
                    render={(formRenderProps) => (
                      <div className="k-d-flex single-field-row single-row-60">
                        <div
                          className={`field-wrapper field-wrapper-100 data-field-wrapper`}
                        >
                          <Field
                            key={showDFModal}
                            id={editorField?.name}
                            name={editorField?.name}
                            data={
                              currentItemTestcase?.uc_ossi_step_run_till_expr
                            }
                            onChange={(e) =>
                              handleInputChange(
                                currentItemTestcase.uc_ossi_app_test_id,
                                currentItemTestcase.uc_ossi_test_case_id,
                                e
                              )
                            }
                            label={editorField?.label}
                            type={"text"}
                            component={TextAreaInput}
                            style={{
                              width: "100%",
                            }}
                            optional={false}
                          />
                        </div>
                      </div>
                    )}
                  />
                </SliderCard.Body>
                <SliderCard.Footer>
                  <Button
                    themeColor={"primary"}
                    onClick={() => {
                      setShowDFModal(false);
                    }}
                  >
                    Close
                  </Button>
                </SliderCard.Footer>
              </SliderCard>
            </DataFieldContentSlider>
          </div>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default RunSetDetail;
